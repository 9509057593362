import styled from 'styled-components';
import { color, font, offset, mediaBreakpointDown, mediaBreakpointUp, vw, hover } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${offset('s', 'padding-top')};
    ${offset('s', 'padding-bottom')};
    ${offset('m', 'padding-left')};
    ${offset('m', 'padding-right')};
    margin: 0 -${vw(20, 375)};
    ${font('text1-b')};
    color: ${color('white')};
    background-color: ${color('red2')};
    width: 100vw;
    transform: translateY(-${vw(24, 375)});

    ${mediaBreakpointDown('md')} {
        @media (max-height: 400px) {
            transform: translateY(-24px);
        }
    }

    ${mediaBreakpointUp('md')} {
        margin: 0 -${vw(40, 768)};
        transform: translateY(-${vw(24, 768)});
    }

    ${mediaBreakpointUp('xl')} {
        margin: 0 -${vw(26, 1280)};
        transform: translateY(-${vw(14, 1280)});
    }

    ${mediaBreakpointUp('xxl')} {
        margin: 0 -${vw(30, 1440)};
        transform: translateY(-${vw(15, 1440)});
    }

    ${mediaBreakpointUp('fhd')} {
        margin: 0 -${vw(40)};
        transform: translateY(-${vw(21)});
    }
`;

export const Text = styled.div`
    width: 100%;
    ${mediaBreakpointUp('lg')} {
        width: 80%;
    }
`;

export const Right = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: auto;

    ${mediaBreakpointDown('lg')} {
        margin-top: 20px;
    }

    a {
        ${font('menu')};
        color: ${color('white')};
        opacity: 0.5;
        transition: opacity 0.4s ease-in-out;

        ${hover(`
            opacity: 1;
        `)};
    }
`;

export const CloseBtn = styled.button`
    padding: 0;
    margin: 0 0 0 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 24px;
    height: 24px;
    background-color: transparent;
    cursor: pointer;
    border: none;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;

    ${hover(`
        opacity: 0.5;
    `)};

    svg {
        width: 24px;
        height: 24px;
        stroke: ${color('white')};
        pointer-events: none;
    }
`;
