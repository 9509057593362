import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { Container, Text, Right, CloseBtn } from './ImportantMessage.styled';
import Link from 'next/link';
import CloseSvg from '@/icons/close.svg';
import webkitLineClamp from 'webkit-line-clamp';
import useIsMatches from '@/hooks/useIsMatches';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { setFirst, setMessage, setLocale, setHide } from '@/redux/importantMessage/importantMessageSlice';
import { getContentApiRoute } from '@/lib/api';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import useTranslation from '@/hooks/useTranslation';

interface ImportantMessageProps {
    /* Component props */
}

const ImportantMessage: React.FC<ImportantMessageProps> = () => {
    const isMobile = useIsMatches('lg');
    const refText = useRef() as MutableRefObject<HTMLDivElement>;
    const dispatch = useDispatch();
    const { first, message, localeMessage, hide } = useSelector(({ importantMessage }: RootState) => importantMessage);
    const { locale } = useRouter();
    const { t: tCommon } = useTranslation('common');

    const fetch = useCallback(async () => {
        try {
            dispatch(setMessage(null));
            const response = await getContentApiRoute('/news', { type: 'imp_messages', check_to_date: true, limit: 1, locale: locale });

            if (!!response.data?.items?.length) {
                dispatch(setMessage(response.data.items[0]));

                if (!!Cookies.get(`importantMessage_${locale}`)) {
                    if (
                        Cookies.get(`importantMessage_${locale}`) !==
                        `${response.data.items[0].slug}/${dayjs(response.data.items[0].publication_date_from).format('DD.MM.YYYY')}`
                    ) {
                        dispatch(setHide(false));
                        Cookies.remove(`importantMessage_${locale}`);
                    } else {
                        dispatch(setMessage(null));
                    }
                } else {
                    dispatch(setHide(false));
                }
            } else {
                dispatch(setMessage(null));
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setFirst(true));
            dispatch(setLocale(locale));
        }
    }, []);

    useEffect(() => {
        if (first && locale === localeMessage) return;
        fetch();
    }, []);

    useEffect(() => {
        if (refText.current && !isMobile) {
            webkitLineClamp(refText.current, 1);
        } else {
            if (refText.current) webkitLineClamp(refText.current, 2);
        }
    }, [refText, isMobile, message]);

    const onclickCloseBtn = (slug: string, dateFrom: string) => {
        dispatch(setHide(true));
        Cookies.set(`importantMessage_${locale}`, `${slug}/${dayjs(dateFrom).format('DD.MM.YYYY')}`, { expires: 1 });
    };

    return message !== null && !hide && !Cookies.get(`importantMessage_${locale}`) ? (
        <Container className="_important">
            {message.title && (
                <Text
                    ref={refText}
                    dangerouslySetInnerHTML={{
                        __html: message.title
                    }}
                />
            )}

            <Right>
                <Link href={`/news-important/${message.slug}`} passHref prefetch={false}>
                    <a>{tCommon('more')}</a>
                </Link>
                <CloseBtn onClick={() => onclickCloseBtn(message.slug, message.publication_date_from)}>
                    <CloseSvg />
                </CloseBtn>
            </Right>
        </Container>
    ) : null;
};

export default ImportantMessage;
